.container-datepicker {
  border: 0;
  z-index: 999999 !important;
}

.container-input-calendar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999 !important;
}

.menu-item-active {
  padding: 8px;
  border-radius: 8px;
  background-color: #5faee8;
}

.menu-item-active svg path {
  fill: white;
}

.vehicle-options-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.vehicle-map {
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  font-family: Lato;
  background-color: green;
}

/* 
dispo
  vert
véhcule engagé, se rendant sur le lieu de la mission
  jaune
véhicule sur place, prend en charge le patient
  orange
départ vers l'hôpital, arriver
  bleu
indisponible
  rouge
*/

/* .vehicle-status-busy */
.vehicle-status-free {
  background-color: green;
}
.vehicle-status-offline,
.vehicle-status-disconnect {
  background-color: red;
}
.vehicle-status-onway {
  background-color: yellow;
}
.vehicle-status-waiting {
  background-color: orange;
}
.vehicle-status-onboard,
.vehicle-status-arrived {
  background-color: blue;
}
/* free
busy
offline
disconnect
onway
onboard
waiting
arrived */
